import { PerpsProvider } from '@kwenta/sdk/types'
import { truncateAddress } from '@kwenta/sdk/utils'
import { FC, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useEnsAvatar, useEnsName } from 'wagmi'

import CopyCheckIcon from 'assets/svg/referrals/copy-check.svg'
import Button from 'components/Button'
import CountBadge from 'components/CountBadge'
import { FlexDivRowCentered } from 'components/layout/flex'
import { Body } from 'components/Text'
import useClickOutside from 'hooks/useClickOutside'
import { WatcherModeIcon } from 'sections/futures/WatcherMode/WatchOrConnectWallet'
import { setOpenModal } from 'state/app/reducer'
import { selectShowModal } from 'state/app/selectors'
import { selectSubAccountsForAccount } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
	selectIsWatcherMode,
	selectSignerWallet,
	selectWatcherWallet,
} from 'state/wallet/selectors'

import ConnectionDot from '../ConnectionDot'
import NetworksSwitcher from '../NetworksSwitcher'
import SubAccountsManagement from '../SubAccountsManagement'

export const MobileWalletActions: FC = () => {
	const dispatch = useAppDispatch()
	const signerWallet = useAppSelector(selectSignerWallet)
	const watcherWallet = useAppSelector(selectWatcherWallet)
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const openModal = useAppSelector(selectShowModal)
	const subAccountList = useAppSelector(selectSubAccountsForAccount)

	const walletAddress = useMemo(
		() => (isWatcherMode ? watcherWallet : signerWallet),
		[isWatcherMode, watcherWallet, signerWallet]
	)
	const { data: ensName } = useEnsName({ address: walletAddress!, chainId: 1 })
	const { data: ensAvatar } = useEnsAvatar({ name: ensName ?? undefined, chainId: 1 })
	const truncatedWalletAddress = truncateAddress(walletAddress! ?? '')
	const [copiedStatus, setCopiedStatus] = useState<boolean>(false)
	const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number } | null>(null)
	const handleClick = useCallback(
		() => (openModal === 'manage_wallet' ? undefined : dispatch(setOpenModal('manage_wallet'))),
		[dispatch, openModal]
	)

	const { ref } = useClickOutside(() => dispatch(setOpenModal(null)))

	const walletLabel = useMemo(() => {
		return ensName || truncatedWalletAddress!
	}, [ensName, truncatedWalletAddress])

	const handleWalletButtonClick = useCallback(() => {
		if (isWatcherMode) {
			dispatch(setOpenModal('watch_or_connect_wallet'))
		} else {
			dispatch(setOpenModal(null))
		}
	}, [isWatcherMode, dispatch])

	const handleCopyToClipboard = useCallback((event: React.MouseEvent) => {
		const x = event.clientX
		const y = event.clientY
		const { protocol, hostname, port } = window.location
		const fullUrl = `${protocol}//${hostname}${port ? `:${port}` : ``}/market/?provider=${
			PerpsProvider.SNX_V2_OP
		}&asset=sETH&wallet=${walletAddress}`

		navigator.clipboard.writeText(fullUrl).then(() => {
			setCopiedStatus(true)
			setTooltipPosition({ x, y })

			setTimeout(() => {
				setCopiedStatus(false)
				setTooltipPosition(null)
			}, 1000)
		})

		event.stopPropagation()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Container onClick={handleClick}>
			{!isWatcherMode && <NetworksSwitcher mobile />}
			<StyledButton
				mono
				noOutline
				isWatcherMode={isWatcherMode}
				onClick={handleWalletButtonClick}
				size="small"
			>
				{isWatcherMode ? (
					<WatcherModeIcon $active />
				) : ensAvatar ? (
					<StyledImage src={ensAvatar} alt={ensName || walletAddress!} width={16} height={16} />
				) : (
					<ConnectionDot />
				)}
				{walletLabel}
				{isWatcherMode && (
					<StyledCopyCheckIcon
						onClick={(event: React.MouseEvent) => handleCopyToClipboard(event)}
					/>
				)}
				{subAccountList.length > 0 && <CountBadge count={subAccountList.length} />}
			</StyledButton>
			{openModal === 'manage_wallet' && (
				<Wrapper ref={ref}>
					<SubAccountsManagement />
				</Wrapper>
			)}
			{copiedStatus && tooltipPosition && (
				<Wrapper>
					<StyledTooltip
						top={`${tooltipPosition.y - 60}px`}
						left={`${tooltipPosition.x - 60}px`}
						padding="5px"
						borderRadius="8px"
					>
						<Body>URL Copied!</Body>
					</StyledTooltip>
				</Wrapper>
			)}
		</Container>
	)
}

const Wrapper = styled.div``

const StyledTooltip = styled.div<{
	top?: string
	left?: string
	padding?: string
	borderRadius?: string
}>`
	position: fixed;
	top: ${(props) => props.top};
	left: ${(props) => props.left};
	padding: ${(props) => props.padding};
	border-radius: ${(props) => props.borderRadius};
	background: ${(props) => props.theme.colors.selectedTheme.button.fill};
	border: ${(props) => props.theme.colors.selectedTheme.border};
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	transition: opacity 0.3s;
	z-index: 100;
	opacity: 1;
`

const StyledCopyCheckIcon = styled(CopyCheckIcon)`
	margin-left: 6px;
	cursor: pointer;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primary};
`

const Container = styled(FlexDivRowCentered)`
	font-size: 12px;
`

const StyledButton = styled(Button)<{ isWatcherMode?: boolean }>`
	text-transform: lowercase;
	height: 41px;
	color: ${(props) =>
		props.isWatcherMode ? props.theme.colors.selectedTheme.watcherMode.active : ''};
`

const StyledImage = styled.img`
	border-radius: 50%;
	margin-right: 8px;
`

export default MobileWalletActions
