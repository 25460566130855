import { FC, memo } from 'react'
import styled, { css } from 'styled-components'

import { useAppSelector } from 'state/hooks'
import { selectIsWatcherMode } from 'state/wallet/selectors'

type PillSize = 'small' | 'medium' | 'large'
type PillColor = 'yellow' | 'gray' | 'red' | 'green' | 'redGray' | 'lightGreen' | 'yellowGray'
type FontWeight = 'regular' | 'bold' | 'black' | 'semiBold'

type PillProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	size?: PillSize
	color?: PillColor
	outline?: boolean
	fullWidth?: boolean
	roundedCorner?: boolean
	weight?: FontWeight
}

const Pill: FC<PillProps> = memo(
	({
		size = 'small',
		color = 'gray',
		roundedCorner = true,
		weight = 'black',
		outline,
		fullWidth,
		...props
	}) => {
		return (
			<BasePill
				$size={size}
				$color={color}
				$outline={outline}
				$fullWidth={fullWidth}
				$roundedCorner={roundedCorner}
				$weight={weight}
				{...props}
			/>
		)
	}
)

const BasePill = styled.button<{
	$size: PillSize
	$color: PillColor
	$outline?: boolean
	$fullWidth?: boolean
	$roundedCorner?: boolean
	$weight?: FontWeight
}>`
	${(props) => css`
		padding: ${props.$size === 'small'
			? '0 5px'
			: props.$size === 'medium'
			? '6px 8px'
			: '10px 15px'};
		height: ${props.$size === 'small' ? '20px' : props.$size === 'medium' ? '24px' : '36px'};
		width: ${props.$fullWidth ? '100%' : 'auto'};
		font-size: ${props.$size === 'large' ? 12 : 10}px;
		line-height: normal;
		background: ${props.theme.colors.selectedTheme.newTheme.pill[props.$color].background};
		color: ${props.theme.colors.selectedTheme.newTheme.pill[props.$color].text};
		border: 1px solid ${props.theme.colors.selectedTheme.newTheme.pill[props.$color].border};
		border-radius: ${props.$roundedCorner ? '50' : '8'}px;
		cursor: pointer;
		text-transform: uppercase;
		font-weight: 600;

		${props.$outline &&
		css`
			background: ${props.theme.colors.selectedTheme.newTheme.pill[props.$color].outline
				.background};
			color: ${props.theme.colors.selectedTheme.newTheme.pill[props.$color].outline.text};
			border: 1px solid
				${props.theme.colors.selectedTheme.newTheme.pill[props.$color].outline.border};
		`}

		&:hover {
			background: ${props.theme.colors.selectedTheme.newTheme.pill[props.$color].hover.background};
			color: ${props.theme.colors.selectedTheme.newTheme.pill[props.$color].hover.text};
		}
	`}

	&:disabled {
		color: ${(props) => props.theme.colors.selectedTheme.button.disabled.text};
		background: transparent;
		box-shadow: none;
		text-shadow: none;
		border: ${(props) => props.theme.colors.selectedTheme.border};
		cursor: not-allowed;
		&::before {
			display: none;
		}
	}
`

export const LabelPill = styled.button<{
	$size: PillSize
	$color: PillColor
	$weight: FontWeight
	$fullWidth?: boolean
}>`
	${(props) => css`
		padding: ${props.$size === 'small'
			? '3 5px'
			: props.$size === 'medium'
			? '6px 8px'
			: '10px 15px'};
		height: ${props.$size === 'small' ? '17px' : props.$size === 'medium' ? '24px' : '36px'};
		width: ${props.$fullWidth ? '100%' : 'auto'};
		font-size: ${props.$size === 'large' ? 12 : 9}px;
		line-height: normal;
		background: ${props.theme.colors.selectedTheme.newTheme.pill[props.$color].background};
		color: ${props.theme.colors.selectedTheme.newTheme.pill[props.$color].text};
		font-weight: 700;
		border: none;
		border-radius: 100px;
		text-transform: uppercase;
		font-weight: ${props.$weight === 'bold' ? 700 : props.$weight === 'semiBold' ? 600 : 400};
	`}
`

export const TransactionPill: FC<PillProps> = memo(({ ...props }) => {
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	return <Pill {...props} disabled={isWatcherMode || props.disabled} />
})

export default Pill
