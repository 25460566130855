import { NetworkId } from '@kwenta/sdk/types'
import { createPublicClient, http } from 'viem'
import { mainnet } from 'viem/chains'

import { SUPPORTED_CHAIN_IDS } from 'constants/network'

export const staticMainnetClient = createPublicClient({
	chain: mainnet,
	transport: http('https://mainnet.infura.io/v3/' + process.env.NEXT_PUBLIC_INFURA_PROJECT_ID),
})

export function isSupportedNetworkId(id: NetworkId): boolean {
	return SUPPORTED_CHAIN_IDS.includes(id)
}
