import { truncateString } from '@kwenta/sdk/utils'
import { FC, useMemo, memo, ReactNode } from 'react'
import styled from 'styled-components'

import Button from 'components/Button'
import Spacer from 'components/Spacer'
import { formatRevert, isUserDeniedError } from 'utils/formatters/error'

import { formatError } from './ErrorNotifier'

type MessageType = 'error' | 'warn' | 'info' | 'secondary'

type ErrorProps = {
	message: string
	messageWithIcon?: ReactNode
	messageType?: MessageType
	formatter?: 'revert' | undefined
	containerStyle?: Record<string, string>
	onClick?: () => void
	retryButton?: {
		size: 'small' | 'xsmall'
		label?: string
		onClick: () => void
	}
}

export const ErrorView: FC<ErrorProps> = memo(
	({
		message,
		messageWithIcon,
		formatter,
		retryButton,
		containerStyle,
		onClick,
		messageType = 'error',
	}) => {
		const formattedMessage = useMemo(() => {
			const formattedError = formatError(message)
			if (formattedError) return formattedError
			switch (formatter) {
				case 'revert':
					return formatRevert(message)
				default:
					return message
			}
		}, [message, formatter])

		if (!messageWithIcon) {
			if (isUserDeniedError(message) || !message) return null
		}

		return (
			<ErrorContainer messageType={messageType} style={containerStyle ?? {}} onClick={onClick}>
				{messageWithIcon ? messageWithIcon : <div>{truncateString(formattedMessage)}</div>}
				{retryButton && (
					<>
						<Spacer height={10} />
						<Button
							variant="danger"
							size={retryButton.size ?? 'small'}
							onClick={retryButton.onClick}
						>
							{retryButton.label}
						</Button>
					</>
				)}
			</ErrorContainer>
		)
	}
)

const ErrorContainer = styled.div<{
	messageType: MessageType
	style: Record<string, string>
	onClick?: () => void
}>`
	color: ${(props) =>
		props.messageType === 'error'
			? props.theme.colors.selectedTheme.button.red.text
			: props.messageType === 'warn'
			? props.theme.colors.selectedTheme.button.yellow.text
			: props.theme.colors.selectedTheme.newTheme.text.primary};
	flex-direction: ${(props) => props.style.flexDirection ?? 'column'};
	display: ${(props) => props.style.display ?? 'inherit'};
	flex: none;
	order: 0;
	flex-grow: 0;
	text-align: ${(props) => props.style.textAlign ?? 'center'};
	font-size: 13px;
	margin: ${(props) => props.style.margin ?? '0 0 16px 0'};
	padding: 15px;
	cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
	border: 1px solid rgba(239, 104, 104, 0);
	background: ${(props) =>
		props.messageType === 'error'
			? props.theme.colors.selectedTheme.button.red.fill
			: props.messageType === 'warn'
			? props.theme.colors.selectedTheme.button.yellow.fill
			: props.messageType === 'secondary'
			? props.theme.colors.selectedTheme.newTheme.button.default.background
			: props.theme.colors.selectedTheme.newTheme.containers.blue.background};
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
	white-space: pre-line;

	:first-letter {
		text-transform: uppercase;
	}
`

export default ErrorView
