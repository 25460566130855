import { PerpsProvider } from '@kwenta/sdk/types'
import { formatNumber, truncateAddress } from '@kwenta/sdk/utils'
import { useAccountModal } from '@rainbow-me/rainbowkit'
import { useRouter } from 'next/router'
import { memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getAddress } from 'viem'
import { Address } from 'viem'

import ConnectedIcon from 'assets/svg/futures/connected-icon.svg'
import DelegationIcon from 'assets/svg/futures/delegation-icon.svg'
import DisconnectedIcon from 'assets/svg/futures/disconnected-icon.svg'
import SelectedAccountIcon from 'assets/svg/futures/selected-account-icon.svg'
import Button from 'components/Button'
import { BaseCard } from 'components/Card/style'
import { FlexDivCol, FlexDivRow, FlexDivRowCentered } from 'components/layout/flex'
import Pill from 'components/Pill'
import { Body } from 'components/Text'
import ROUTES from 'constants/routes'
import useBalance from 'hooks/useBalance'
import { DelegationSubTab } from 'sections/futures/DelegationTrading/types'
import WatchOrConnectWallet, {
	WatcherModeIcon,
} from 'sections/futures/WatcherMode/WatchOrConnectWallet'
import { setOpenModal, setOpenModalWithParams } from 'state/app/reducer'
import { fetchSubAccountsForAccount } from 'state/futures/actions'
import {
	selectPerpsProvider,
	selectSelectedSubAccountAddress,
} from 'state/futures/common/selectors'
import { setSelectedSubAccountAddress } from 'state/futures/reducer'
import {
	selectIsIsolatedMarginProvider,
	selectSubAccountsForAccount,
} from 'state/futures/selectors'
import { ManageModalType } from 'state/futures/types'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { resetWalletAddress } from 'state/wallet/actions'
import { selectIsSignerMode, selectIsWatcherMode, selectSignerWallet } from 'state/wallet/selectors'
import media from 'styles/media'

const SubAccountsManagement = memo(() => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const router = useRouter()
	const signerWallet = useAppSelector(selectSignerWallet)
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const isSignerMode = useAppSelector(selectIsSignerMode)
	const perpsProvider = useAppSelector(selectPerpsProvider)
	const isIsolated = useAppSelector(selectIsIsolatedMarginProvider)
	const subAccountList = useAppSelector(selectSubAccountsForAccount)
	const selectedSubAccountAddress = useAppSelector(selectSelectedSubAccountAddress)
	const { balance: ethBalance } = useBalance(signerWallet!)
	const { openAccountModal } = useAccountModal()

	const isMarketsPage = router.pathname.includes('/market')

	const onDismiss = useCallback(() => dispatch(setOpenModal(null)), [dispatch])

	const handleSelectedSubAccount = useCallback(
		(address: Address) => {
			dispatch(setSelectedSubAccountAddress(address))
			dispatch(resetWalletAddress({ address, selectedType: 'sub_account' }))
		},
		[dispatch]
	)

	const handleResetSubAccount = useCallback(() => {
		dispatch(resetWalletAddress({ address: signerWallet, selectedType: 'signer' }))
		dispatch(setSelectedSubAccountAddress(''))
	}, [dispatch, signerWallet])

	const handleOpenSubAccountsManagement = useCallback(() => {
		if (!isMarketsPage) {
			router.push(ROUTES.Markets.Home(perpsProvider) + '&manage_account=true')
		} else {
			const params = { tab: ManageModalType.Delegation, subTab: DelegationSubTab.SubAccounts }
			dispatch(fetchSubAccountsForAccount([perpsProvider]))
			if (isIsolated) {
				dispatch(
					setOpenModalWithParams({
						type: 'futures_deposit_withdraw_isolated_margin',
						params,
					})
				)
			} else {
				dispatch(
					setOpenModalWithParams({
						type: 'futures_deposit_withdraw_cross_margin',
						params,
					})
				)
			}
		}
	}, [isIsolated, perpsProvider, dispatch, router, isMarketsPage])

	const handleOpenEnableWatcherMode = useCallback(() => {
		dispatch(setOpenModal('enable_watcher_mode'))
	}, [dispatch])

	useEffect(() => {
		if (router.query.manage_account && isMarketsPage) {
			router.replace(ROUTES.Markets.Home(perpsProvider), undefined, { shallow: true })
			handleOpenSubAccountsManagement()
		}
	}, [
		router.query.manage_account,
		handleOpenSubAccountsManagement,
		router,
		perpsProvider,
		isMarketsPage,
	])

	return (
		<SubAccountsTabContainer>
			<CardsContainer>
				{isWatcherMode ? (
					<WatchOrConnectWallet onDismiss={onDismiss} />
				) : (
					<>
						<FlexDivCol rowGap="15px">
							<FlexDivRowCentered justifyContent="flexStart" columnGap="12px">
								<DelegationIcon height={48} width={48} />
								<FlexDivCol rowGap="5px">
									<Body weight="bold" color="primary">
										{truncateAddress(signerWallet ?? '')}
									</Body>
									<Body color="secondary">
										{`${formatNumber(ethBalance || '0', {
											suggestDecimals: true,
										})} ETH`}
									</Body>
								</FlexDivCol>
							</FlexDivRowCentered>
							<FlexDivRow columnGap="10px">
								<Button size="xsmall" fullWidth onClick={handleOpenEnableWatcherMode}>
									<WatcherModeIcon />
									{t('common.wallet.watch')}
								</Button>
								<Button
									size="xsmall"
									fullWidth
									disabled={!signerWallet}
									onClick={isSignerMode ? openAccountModal : handleResetSubAccount}
								>
									{isSignerMode
										? t('common.wallet.wallet')
										: t('futures.market.trade.margin.modal.delegation.connect')}
								</Button>{' '}
							</FlexDivRow>
						</FlexDivCol>
						<FlexDivRowCentered>
							<Body weight="bold">
								{t('futures.market.trade.margin.modal.delegation.delegated-access')}
							</Body>
							<TextButton
								color="yellow"
								disabled={perpsProvider === PerpsProvider.PERENNIAL_V2_ARB}
								onClick={handleOpenSubAccountsManagement}
							>
								{t('futures.market.trade.margin.modal.delegation.manage')}
							</TextButton>
						</FlexDivRowCentered>
						{subAccountList.map(({ address, nickname }) => (
							<SubAccountRowContainer
								key={address}
								justifyContent="space-between"
								onClick={() => {
									return selectedSubAccountAddress === address
										? handleResetSubAccount()
										: handleSelectedSubAccount(getAddress(address) as Address)
								}}
							>
								<FlexDivRow justifyContent="flexStart" columnGap="12px">
									{address === selectedSubAccountAddress ? (
										<SelectedAccountIcon height={32} width={32} />
									) : (
										<DelegationIcon height={32} width={32} />
									)}
									<FlexDivCol rowGap="5px">
										<Body weight="bold" color="primary">
											{nickname ||
												t('futures.market.trade.margin.modal.delegation.default-account-name')}
										</Body>
										<Body color="secondary">{truncateAddress(address)}</Body>
									</FlexDivCol>
								</FlexDivRow>
								<FlexDivRowCentered columnGap="5px">
									{address === selectedSubAccountAddress ? (
										<>
											<Body color="secondary" size="xsmall">
												{t('futures.market.trade.margin.modal.delegation.connected')}
											</Body>
											<ConnectedIcon />
										</>
									) : (
										<>
											<Body color="secondary" size="xsmall">
												{t('futures.market.trade.margin.modal.delegation.connect')}
											</Body>
											<DisconnectedIcon />
										</>
									)}
								</FlexDivRowCentered>
							</SubAccountRowContainer>
						))}
					</>
				)}
			</CardsContainer>
		</SubAccountsTabContainer>
	)
})

const TextButton = styled(Pill)`
	cursor: pointer;
	:hover {
		opacity: 0.8;
		font-weight: 700;
	}
`
const SubAccountRowContainer = styled(FlexDivRowCentered)`
	cursor: pointer;
`

const SubAccountsTabContainer = styled.div`
	z-index: 100;
	position: fixed;
	top: 15px;
	right: 70px;

	${media.lessThan('lg')`
		position: absolute;
		width: 100%;
		z-index: 0;
		overflow-x: auto;
		bottom: 0;
		right: 0;
		top: initial;
		gap: 10px;
	`}

	${media.greaterThan('lg')`
		margin-top: 56px;
	`}
`

const CardsContainer = styled(BaseCard)`
	display: flex;
	flex-direction: column;
	width: 300px;
	row-gap: 20px;

	${media.lessThan('lg')`
		display: flex;
		flex-direction: column;
		width: 100%;
		row-gap: 20px;
		border-radius: 15px 15px 0 0;
	`}
`

export default SubAccountsManagement
