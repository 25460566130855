import styled, { css } from 'styled-components'

export const MenuButton = styled.div<{
	isActive?: boolean
	isLink?: boolean
}>`
	outline: none;
	width: 100%;
	font-size: 19px;
	font-weight: 700;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
	text-transform: capitalize;
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${(props) =>
		props.isActive &&
		css`
			color: ${props.theme.colors.common.primaryWhite};
			path {
				${props.isLink ? 'fill' : 'stroke'}: ${props.theme.colors.common.primaryWhite};
			}
		`}
`
