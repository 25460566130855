import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useRouter } from 'next/router'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { isAddress } from 'viem'
import { Address } from 'viem/accounts'
import { useAccount } from 'wagmi'

import EyeIcon from 'assets/svg/app/eye.svg'
import Button from 'components/Button'
import Input from 'components/Input/Input'
import InputHeaderRow from 'components/Input/InputHeaderRow'
import { FlexDivCol } from 'components/layout/flex'
import Spacer from 'components/Spacer'
import ROUTES from 'constants/routes'
import useENS from 'hooks/useENS'
import ConnectionDot from 'sections/shared/Layout/AppLayout/Header/ConnectionDot'
import { setOpenModal } from 'state/app/reducer'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { resetWalletAddress } from 'state/wallet/actions'
import { disconnect } from 'state/wallet/reducer'
import { selectIsWatcherMode, selectSignerWallet } from 'state/wallet/selectors'

import { StyledBaseModal } from '../TradeSmartMargin/WithdrawSmartMargin'

type WatchOrConnectWalletProps = {
	onDismiss: () => void
	isMobile?: boolean
}

const WatchOrConnectWallet: FC<WatchOrConnectWalletProps> = memo(({ onDismiss, isMobile }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const router = useRouter()
	const { openConnectModal } = useConnectModal()

	const { address: persistedSignerWallet } = useAccount()
	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const provider = useAppSelector(selectPerpsProvider)

	const [watchWalletAddressInput, setWatchWalletAddressInput] = useState<string>('')
	const { ensAddress } = useENS(watchWalletAddressInput)

	const watchWalletAddress = useMemo(() => {
		if (ensAddress) {
			return ensAddress
		}

		if (watchWalletAddressInput && isAddress(watchWalletAddressInput)) {
			return watchWalletAddressInput
		}

		return null
	}, [watchWalletAddressInput, ensAddress])

	const handleExitWatcherMode = useCallback(() => {
		if (persistedSignerWallet) {
			dispatch(resetWalletAddress({ address: persistedSignerWallet, selectedType: 'signer' }))
		} else if (openConnectModal) {
			dispatch(disconnect())
			openConnectModal()
		}
		const { pathname, query } = router
		if (query.wallet) {
			delete query.wallet
			router.replace({ pathname, query }, undefined, { shallow: true })
		}
		onDismiss()
	}, [persistedSignerWallet, openConnectModal, onDismiss, dispatch, router])

	const handleWatchWalletAddress = useCallback(() => {
		if (watchWalletAddress && isAddress(watchWalletAddress)) {
			dispatch(
				resetWalletAddress({ address: watchWalletAddress as Address, selectedType: 'watcher' })
			)
			router.push(ROUTES.Markets.Watcher(watchWalletAddress, provider))
			onDismiss()
		}
	}, [watchWalletAddress, dispatch, router, provider, onDismiss])

	return (
		<Container isMobile={isMobile}>
			{isWatcherMode && (
				<>
					<Button size="small" variant="flat" noOutline onClick={handleExitWatcherMode} mono>
						<ConnectionDot />
						{t('common.wallet.connect-wallet')}
					</Button>
					<StyledSpacer />
				</>
			)}
			<FlexDivCol>
				<InputHeaderRow label="Address or ENS" />
				<WatcherInput
					autoFocus
					placeholder="0x"
					onChange={(e) => setWatchWalletAddressInput(e.target.value)}
				/>
			</FlexDivCol>
			<Button
				disabled={!watchWalletAddress}
				size="small"
				variant="flat"
				noOutline
				onClick={handleWatchWalletAddress}
				mono
			>
				<WatcherModeIcon disabled={!watchWalletAddress} />
				{t('common.wallet.watch')}
			</Button>
		</Container>
	)
})

export const WatchOrConnectWalletModal: FC = memo(() => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const signerWallet = useAppSelector(selectSignerWallet)

	const handleOnModalDismiss = () => dispatch(setOpenModal(null))

	return (
		<StyledBaseModal
			title={
				!!signerWallet
					? t('futures.market.trade.margin.modal.delegation.connect')
					: t('futures.market.trade.margin.modal.watcher.title')
			}
			isOpen
			onDismiss={handleOnModalDismiss}
		>
			<WatchOrConnectWallet onDismiss={handleOnModalDismiss} isMobile />
		</StyledBaseModal>
	)
})

export const WatcherModeIcon = styled(EyeIcon)<{ disabled?: boolean; active?: boolean }>`
	margin-right: 6px;
	path {
		fill: ${(props) =>
			props.theme.colors.selectedTheme.watcherMode[
				props.disabled ? 'disabled' : props.$active ? 'active' : 'inactive'
			]};
	}
`

const Container = styled(FlexDivCol)<{ isMobile?: boolean }>`
	row-gap: ${(props) => (props.isMobile ? '35px' : '15px')};
	padding-top: ${(props) => (props.isMobile ? '20px' : '')};
`

const StyledSpacer = styled(Spacer)<{ marginTop?: number }>`
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	width: 100%;
`

const WatcherInput = styled(Input)`
	height: 40px;
	font-size: 13px;
	font-weight: 400;
	font-feature-settings: 'tnum';
`

export default WatchOrConnectWallet
